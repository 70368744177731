<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-eow-products-categories' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Categories</v-btn
        >
      </v-row>
      <v-row no-gutters align="center" class="mt-0">
        <h1>{{ guide.name }}</h1>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit Category</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <div>
              <v-simple-table class="table table-bordered">
                <thead>
                  <tr>
                    <th v-for="country in countries" :key="country">
                      {{ country }}
                    </th>
                    <th class="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in maxLength" :key="index">
                    <td v-for="country in countries" :key="country">
                      {{ groupedAttributes[country][index]?.value || "-" }}
                    </td>
                    <th class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="blue lighten-4 blue--text"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="red lighten-4 red--text"
                            v-on="on"
                          >
                            <v-icon x-small>mdi-archive</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <!-- <v-data-table :items="guide" :headers="tableHeaders2">
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.sizeForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openDeleteSize(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {},

  data() {
    return {
      breadcrumbs: [
        {
          text: "Categories",
          disabled: false,
          to: { name: "module-eow-products-categories" },
          exact: true,
        },
      ],

      groupedSizes: [],
      searchTerm: "",
      fieldsMessage: {
        message: null,
      },
      fieldsSale: {
        early_access: false,
        general_sale: false,
      },
      columns: [],
      groupedData: [],
      loading: false,
      loadingSale: false,
      errors: {},
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
    };
  },

  computed: {
    countries() {
      return Object.keys(this.groupedAttributes);
    },
    maxLength() {
      return Math.max(
        ...this.countries.map((c) => this.groupedAttributes[c].length)
      );
    },

    groupedAttributes() {
      const groups = {};
      if (this.guide.sizes) {
        this.guide.sizes.forEach((attrOpt) => {
          const attr = attrOpt.country;

          if (!groups[attr]) groups[attr] = [];
          groups[attr].push(attrOpt);
        });
      }

      return groups;
    },

    guide() {
      return this.$store.getters["eow/productsStore/guide"];
    },
  },

  methods: {
    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        categoryId: this.$route.params.categoryId,
        fields: this.fieldsMessage,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("eow/categorysStore/saveCategoryEmail", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
    },

    resetFormSale: function () {
      this.loadingSale = false;
      this.isEditing = true;
    },
  },
};
</script>
